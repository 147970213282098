var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-grid"},[(_vm.title)?_c('h2',{domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),_c('v-row',_vm._l((_vm.products),function(product,index){return _c('v-col',{key:product.productId,staticClass:"product-col",attrs:{"cols":"12"}},[(!_vm.modify)?_c('ProductCard',{attrs:{"product":product,"position":_vm.initPosition + index},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', {
            product: product,
            index: _vm.initPosition + index
          })}}}):_vm._e(),(_vm.modify)?_c('ProductCardReplacement',{attrs:{"modify":_vm.modify,"product":product,"position":_vm.initPosition + index},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', {
            product: product,
            index: _vm.initPosition + index
          })}}}):_vm._e()],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
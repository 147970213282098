<template>
  <v-card
    class="product-card elevation-0 d-flex flex-column flex-sm-row justify-center align-center align-md-start"
    :id="`product-card-${product.slug}`"
  >
    <v-col cols="12" sm="2" class="d-flex justify-center align-center">
      <img
        :src="product.mediaURL"
        onerror="this.onerror=null;this.src='/no-icon.png'"
        class="product-img text-align-center"
        :alt="'Immagine' + product.name"
        :title="
          product.name + ' (' + product.codInt + '-' + product.codVar + ')'
        "
      />
    </v-col>
    <v-row no-gutters class="w-100 pa-3 justify-sm-space-between">
      <v-col cols="6" md="3">
        <span class="font-weight-bold">{{ product.name }}</span>
      </v-col>
      <v-col cols="4" class="d-none d-md-block">
        <div>
          <strong> {{ productCharacteristics.title }}</strong>
          <div
            class="d-flex"
            v-for="characteristic in productCharacteristics.characteristics"
            :key="characteristic.index"
          >
            <strong class="mr-2">{{ characteristic.title }}</strong>
            <span>{{ characteristic.value }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="6" md="3">
        <div class="d-flex flex-column justify-center align-end">
          <small class="text-right"
            >Product number: {{ product.productId }}</small
          >
          <v-btn
            class="product-card-cta elevation-0 grey lighten-3"
            :to="{ name: 'Product', params: { slug: product.slug } }"
            ><span class="font-weight-normal">More Information</span></v-btn
          >
          <v-btn
            v-if="modify"
            class="product-card-cta elevation-0 grey lighten-3 ma-5"
            @click="selectAsReplacement(product)"
            ><span class="font-weight-normal">Select item</span></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss">
.product-card {
  .v-rating .v-icon {
    font-size: 16px;
  }
  .product-img {
    width: 170px;
    height: auto;
    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      width: 100%;
    }
  }
  .product-card-cta {
    max-width: fit-content;
    background-color: var(--v-grey-lighten3);
    span {
      color: var(--v-secondary-darken4);
    }
  }
  .product .description .name {
    font-family: $heading-font-family;
  }
  .promo-wrapper {
    position: absolute;
    top: -1px;
    left: -1px;
    display: flex;
    flex-direction: column;
    z-index: 3;
  }
  .cart-item-info {
    margin-bottom: -21px;
  }

  .top {
    height: 26px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .product-price {
      width: 140px;
      text-align: center;
    }
  }

  .actions {
    .selectOptions {
      margin-right: 3px;
    }
  }

  .selectOptionsMenu {
    z-index: 12;
  }
}
</style>
<script>
//import ProductAcqLimit from "./ProductAcqLimit.vue";
//import ProductPrice from "./ProductPrice.vue";
//import ProductQty from "./ProductQty.vue";
//import ProductRating from "@/components/product/ProductRating.vue";
//import SelectedOptionsMenu from "@/components/product/SelectedOptionsMenu.vue";

import productMixin from "~/mixins/product";

import { mapState, mapGetters } from "vuex";

import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductCard",
  props: {
    product: { type: Object, required: true },
    position: { type: Number, default: undefined },
    modify: {
      type: Boolean,
      default: false
    }
  },
  components: {
    //   ProductAcqLimit,
    //   ProductPrice,
    // ProductQty,
    // ProductRating,
    // SelectedOptionsMenu
  },
  mixins: [productMixin],
  data() {
    return {
      selectOptionsArray: [],
      selectedOptions: {},
      key: 0,
      productCharacteristics: {
        title: "Specification",
        characteristics: [
          {
            title: "Type:",
            value: "W"
          },
          {
            title: "Size:",
            value: "2400"
          },
          {
            title: "Shaft length(lz):",
            value: "1010"
          },
          {
            title: "Item no. attachment WH-A:",
            value: "133836"
          },
          {
            title: "Item no. attachment WH-I:",
            value: "133836"
          },
          {
            title: "Torque of clutch in daNm:",
            value: "0"
          },
          {
            title: "Rotation speed max. RPM:",
            value: "1000 UPM"
          },
          {
            title: "Form attachment WH-A:",
            value: "KP1 3/8"
          },
          {
            title: "Form attachment WH-I:",
            value: "KP1 3/8"
          },
          {
            title: "Length of guard tube (outer):",
            value: "795"
          },
          {
            title: "Cone Type WH-A:",
            value: "RP6"
          },
          {
            title: "Length of guard tube (inner):",
            value: "790"
          },
          {
            title: "Inner profile length:",
            value: "885"
          },
          {
            title: "Attachment type of profile:",
            value: "AA"
          },
          {
            title: "Cone type WH-I:",
            value: "RP6"
          },
          {
            title: "Nipple form / position I/A:",
            value: "LB LB"
          },
          {
            title: "SNR cone WH-A:",
            value: "199175"
          },
          {
            title: "Protective colour:",
            value: "Yellow"
          },
          {
            title: "Chain WH-A:",
            value: "K"
          },
          {
            title: "Outer profile length:",
            value: "865"
          },
          {
            title: "SNR cone WH-I:",
            value: "199175"
          },
          {
            title: "Cross seal",
            value: "STANDARD"
          },
          {
            title: "Abbreviation attachment WH-A:",
            value: "ASGE"
          },
          {
            title: "Profile tube - outer type:",
            value: "S5"
          },
          {
            title: "Profile tube - inner type:",
            value: "S4LH"
          },
          {
            title: "Guard type:",
            value: "ST25Z"
          }
        ]
      }
    };
  },
  computed: {
    ...mapGetters({
      getItem: "cart/getItem",
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    promoProductClasses() {
      return this.product.productClasses.filter(
        pClass => pClass.productClassGroupId === 6
      );
    },
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.isNew) {
        productClasses.push("new-product");
      }
      if (this.product.badge) {
        productClasses.push(this.product.badge.cssClass);
      }
      return productClasses;
    }
  },
  methods: {
    giftAdded() {
      this.plus();
    },
    onOptionChanged(args) {
      let selectOption = args.selectOption;
      let option = args.option;
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.value;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    },
    handleClick() {
      this.$emit("selectProduct");
    },
    selectAsReplacement(product) {
      global.EventBus.$emit("selectAsReplacement", product);
    }
  },
  mounted() {
    if (this.product.selectOptions && this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  }
};
</script>
